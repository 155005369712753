import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Learnings and tools
// Public APIs, React, Mapbox, deck.gl, react-vis
import { ReactComponent as APIIcon } from './svg/api.svg';
import { ReactComponent as ReactIcon } from './svg/react.svg';
import { ReactComponent as MapboxIcon } from './svg/mapbox.svg';
import { ReactComponent as DeckglIcon } from './svg/deckgl.svg';
import { ReactComponent as ChartsIcon } from './svg/charts.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import ExternalLink from '@common/ExternalLink';
import DemoLink from '@common/DemoLink';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  LearningsGrid,
  Learning,
  LearningTopic,
  // Element,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './city-display.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/city-display/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/city-display/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>
              <Paragraph>{description}</Paragraph>
              <Paragraph>
                <DemoLink href="https://city.aitorperez.com/">
                  Try a limited demo
                </DemoLink>
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="City display"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <APIIcon />
                  <LearningTopic>Public APIs</LearningTopic>
                </Learning>
                <Learning>
                  <ReactIcon />
                  <LearningTopic>React</LearningTopic>
                </Learning>
                <Learning>
                  <MapboxIcon />
                  <LearningTopic>Mapbox</LearningTopic>
                </Learning>
                <Learning>
                  <DeckglIcon />
                  <LearningTopic>Deck.gl</LearningTopic>
                </Learning>
                <Learning>
                  <ChartsIcon />
                  <LearningTopic>react-vis</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Title>Strategy</Title>
              <Paragraph>
                I was requested to build a technological Proof of Concept, for a
                showroom dedicated to innovation where two of the areas of
                interest were Smart Cities and IoT technologies.
              </Paragraph>

              <Paragraph>The brief asked for:</Paragraph>

              <List>
                <Item>An interactive experience for a touchscreen</Item>
                <Item>
                  A way to showcase the use of data generated by sensorized
                  cities/things
                </Item>
                <Item>
                  Something eye-catching that stands out in a room full of other
                  displays
                </Item>
              </List>

              <Title>Scope</Title>
              <Paragraph>
                Right from the start I chose to use 2 types of data to showcase
                mixing internal and external sources as could be done in a
                real-world application:
              </Paragraph>

              <List>
                <Item>Publicly available data from the city</Item>
                <Item>Private data from our corporate building's sensors</Item>
              </List>

              <Paragraph>
                Then, to make the experience visually appealling I selected 2
                core instruments:
              </Paragraph>

              <List>
                <Item>The city map</Item>
                <Item>Charts</Item>
              </List>

              <Paragraph>
                Reviewing all the available open data APIs in Barcelona, I chose
                data from Barcelona's municipal bicycle system and data from
                TMB's urban bus network forecasting.
              </Paragraph>

              <Paragraph>
                For the bicycle system{' '}
                <ExternalLink regular href="https://www.bicing.cat/">
                  Bicing
                </ExternalLink>{' '}
                provides an endpoint with a catalogue of geopositioned stations
                and another endpoint giving real-time status on all stations.
              </Paragraph>

              <Paragraph>
                For the buses{' '}
                <ExternalLink regular href="https://www.tmb.cat/">
                  TMB
                </ExternalLink>{' '}
                also gives 2 useful API endpoints: One gives the geometry for
                all routes and the position of all stations. The other gives the
                ETA of the next vehicles for a specific station (but the
                vehicles themselves aren't uniquely identified).
              </Paragraph>

              <Paragraph>
                Of the in-house sensors, I selected data from lights, power,
                waste and CO2 data.
              </Paragraph>

              <Title>Structure</Title>
              <Paragraph>
                Instead of trying to fit as many data as possible on screen, and
                thinking on the eye-catching goal of the brief, I structured the
                data in 2 tiers to have a clean, minimal homescreen:
              </Paragraph>

              <List>
                <Item>
                  Only the most recent / up-to-date value should be shown
                  initially
                </Item>
                <Item>
                  Each relevant item should then allow the user to drill-down
                  into complementary data or a series of data points
                </Item>
              </List>

              <Paragraph>
                This defined what concrete interface elements were needed for
                this project:
              </Paragraph>

              <List>
                <Item>
                  A map showing bike stations, bus stops and buses (these are
                  the clickable elements)
                </Item>
                <Item>A bike counter and a bus counter</Item>
                <Item>A bike station panel with the docking clamps status</Item>
                <Item>A bus stop panel with the next incoming vehicles</Item>
                <Item>A bus panel with the vehicle route</Item>
                <Item>
                  A real-time sensor reading (another clickable element)
                </Item>
                <Item>
                  A sensor panel with a chart showing a time-series of readings
                </Item>
              </List>

              <Title>Skeleton</Title>
              <Paragraph>
                From a technological perspective, I prototyped the code
                necessary to build all the visualisations.
              </Paragraph>

              <Paragraph>
                Using the bike API I mapped all stations and retrieved the
                number of bikes, ebikes, free parking slots and malfunctions
                (much like the official service does). This was mostly a
                straight API to map implementation without much manipulation
                needed.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="early-bike-mapping"
                overflow
                alt="Early bike mapping concept"
                description="Early prototype of mapping bike stations, including a (later discarded) heatmap of available bikes"
              />

              <Paragraph>
                For the buses, though, I had to retrieve readings for each pair
                stop-line and then develop a method to, grouping them by routes
                and, considering lengths between stops, stop coordinates and
                average bus speed, extrapolate where each vehicle should be
                geographically on its route (eliminating duplicate observations
                for the same vehicle between stops).
              </Paragraph>

              <Paragraph>
                This resulted in each bus being reasonably positioned
                circulating on its route at a speed that matches their forcasted
                ETAs (and minding the speed limit, actually).
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="early-bus-mapping"
                alt="Early bus mapping concept"
                overflow
                description="First attempt at mapping the observable ETAs for the routes crossing a single street"
              />

              <Paragraph>
                From a visual perspective, the following directions informed the
                sketching and wireframing:
              </Paragraph>

              <List>
                <Item>
                  The map should be the star of the show, and feature
                  prominently
                </Item>
                <Item>
                  The bus progress through the route should try to mimic the
                  subway route displays because of the familiarity the user
                  would have reading it
                </Item>
                <Item>
                  The bike station panel should need to convey if there are
                  bikes available and if there are docking spots available more
                  easily than the official service does
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="panel-sketches"
                overflow
                alt="Sketches for the panels"
                description="Sketches for the layout and some of the panels"
              />

              <Image
                edges={data.designs.edges}
                name="bus-sketches"
                overflow
                alt="Sketches for the bus routes"
                description="Sketches for the vehicle panel and for the position representation inside the bus route"
              />

              <Title>Surface</Title>

              <Paragraph>
                From the rough map put together during the technical prototype
                phase, I built the different panels, visualizations and
                interactions between elements.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="ss-early-designs"
                overflow
                alt="Early designs"
                description="Implementation of early designs"
              />

              <Paragraph>
                For the final polish, I inverted the usual white map colour
                scheme to develop a black/grey map that sits quietly at the
                background and allows all the other information to stand out
                with a colorful palette.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="ss-final-map"
                overflow
                alt="Final map design"
              />

              <Image
                edges={data.designs.edges}
                name="ss-details"
                overflow
                alt="Final details"
              />

              <Image
                edges={data.designs.edges}
                name="ss-final-bus"
                overflow
                alt="Final bus displays"
              />

              <Image
                edges={data.designs.edges}
                name="ss-final-metric"
                overflow
                alt="Final sensor display"
              />

              <Image
                edges={data.designs.edges}
                name="ss-final-display"
                overflow
                alt="Finished display"
                description="Final display showcasing the status of the entire public bike network and tracking all the public buses in Barcelona in real-time"
              />
            </Content>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
